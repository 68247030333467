import { Injectable, signal } from '@angular/core';
import { Modal } from './modal.interface';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  modalContent = signal<Modal>({
    isOpen: false,
  });
  modalClass = signal<string>('');
}
